
p {
    color: #555;
}

.site-logo img {
    max-height: 2em;
    float: left;
    padding: 11px 4px 0 0;
}

nav.site-nav {
    z-index: 100;
}

ul.blog, ul.archive {

    list-style: none;
    margin-left: 0;

    a {
        color: #d50000;
    }

    li {
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        margin-bottom: 3rem;
        padding: 1.25rem;
    }
}

ul.blog {

    li {
        div.image {
            display: block;
        }

        img {
            border: 0;
            max-height: 200px;
            max-width: 200px;
            float: left;
            margin: 20px 20px 0 0;
        }

        div.snippet {
            display: flow-root;
        }

        span.readmore {
            float: right;
        }
    }
}

ul.archive {
    li a.title {
        font-weight: bold;
    }

    li {
        color: #8e8e8e;
    }
}

.slack-invite {
    img {
        padding: 1em 0 3em 0;
    }
}

form.contact {
    margin-top: 3em;

    div.form-group {
        padding-bottom: 1em;
    }

    label {
        display: block;
        font-weight: 700;
        margin-bottom: .5em;
    }

    input, textarea {
        font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
        border: 1px solid transparent;
        height: 2.0em;
        background-color: #fff;
        border-color: #dbdbdb;
        border-radius: 4px;
        box-shadow: inset 0 .0625em .125em rgba(10,10,10,.05);
        width: 100%;

        font-size: 1em;
        padding: calc(.5em - 1px) calc(.75em - 1px);
    }

    textarea {
        height: 8em;
        padding: calc(.75em - 1px);
    }

    input:focus, textarea:focus {
        outline: none;
        border: 1px solid transparent;
        border-color: #3273dc;
        box-shadow: 0 0 0 .125em rgba(50,115,220,.25);;
    }

    button {
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.5;
        padding: calc(.5em - 1px) 1em;
        border: 1px solid transparent;
        border-radius: 4px;
        border-width: 1px;
        border-color: transparent;
        box-shadow: none;
    }

    button.submit {
        color: #fff;
        background-color: #2366d1;
    }
}

footer {
    background-color: #363636;

    div.bio {
        width: 45%;
        float: right;
    }

    div.about, div.contact {
        width: 20%;
    }

    h1, h2, p, span, a:link, a:hover, a:visited {
        color: #a9a9a9;
    }

    h4, a:hover {
        color: #dadada;
    }

    h4 {
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin-left: 0;

        li.social.icons {
            padding-top: 18px;
        }
    }

    ul.policies {
        display: flex;
        padding-top: 20px;

        li {
            font-size: small;
            padding-right: 10px;

            a {
                color: #6f6f6f;
            }
        }
    }

    span.email {
        font-weight: 500;
        padding-right: 5px;
    }
}
